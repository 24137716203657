import React, { useContext, useState, useEffect } from 'react';
import { Logo, LogoMark } from '../components/icons';
import { useLocation } from '@reach/router';
import Fade from 'react-reveal/Fade';
import Link from "../utils/link"

// Function to count total quantity
const countQuantity = lineItems => {
  let quantity = 0;
  lineItems.forEach(item => {
    quantity += item.quantity;
  });
  return quantity;
};

const Header = ({handleLinkClick }) => {
  const location = useLocation();
  const [scrollDirection, setScrollDirection] = useState('none');
  const [scrolled, setScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [offCanvas, setOffCanvas] = useState(false);
  const [activeParentMenu, setActiveParentMenu] = useState(null); // Track which parent menu is active
  const [activeSubMenu, setActiveSubMenu] = useState(null); // Track which sub-menu is active

  let lastScrollTop = 0; // Declare the variable using 'let'

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrolled = scrollTop >= 50;
    let direction = 'none';

    if (scrollTop >= 100) {
      if (scrollTop > lastScrollTop) {
        direction = 'down';
      } else {
        direction = 'up';
      }
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

    setScrollDirection(direction);
    setScrolled(scrolled);
  };

  const handleHeaderHover = () => {
    setIsHovered((prevIsHovered) => !prevIsHovered);
    setScrollDirection('none');
  };

  // Toggle off-canvas menu
  const toggleOffCanvas = () => setOffCanvas(prevState => !prevState);

  const hideOffCanvas = () => setOffCanvas(false);

  // Handle link click
  const handleLinkClickHandler = link => {
    handleLinkClick(link);
    hideOffCanvas();
  };

  useEffect(() => {
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const headerClass = `header${
    scrollDirection !== 'none' ? ` scrolled-${scrollDirection}` : ''
  }${scrolled ? ' scrolled' : ''}${isHovered ? ' scrolled-up' : ''}${offCanvas ? ' offcanvas-active' : ''}`;

  // Toggle parent menu visibility
  const toggleParentMenu = parentMenu => {
    // handleLinkClick('/sister/collection');
    if (activeParentMenu === parentMenu) {
      setActiveParentMenu(null);
      setActiveSubMenu(null); // Reset sub-menu when switching parent menu
    } else {
      setActiveParentMenu(parentMenu);
      setActiveSubMenu(null); // Reset sub-menu when switching parent menu
    }
  };

  const toggleSubMenu = (subMenu) => {
    setActiveSubMenu((prev) => prev === subMenu ? null : subMenu);
  };

  const isActive = (path) => location.pathname === path ? 'active' : '';


  return (
    <>
        <header className={`${headerClass}`} onMouseEnter={handleHeaderHover} onMouseLeave={handleHeaderHover}>
          <div className="header__inner">
              <div className="header__col">
                <Link 
                  to="/" 
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClickHandler('/');
                  }}
                >
                  <h2 className='title'>Architecture Studio</h2>
                </Link>

              </div>


              <div className="header__col">
                <nav>
                  <ul>
                    <li className={isActive('/')}>
                        <Link 
                          to="/" 
                          onClick={(e) => {
                            e.preventDefault();
                            handleLinkClickHandler('/');
                          }}
                        >
                        Projects
                      </Link>
                    </li>
                    <li className={isActive('/journal/')}>
                      <Link 
                to="/journal/" 
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClickHandler('/journal/');
                }}
              >
                        Journal
                        </Link>
                    </li>
                    <li className={isActive('/studio/')}>

                      <Link 
                to="/studio/" 
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClickHandler('/studio/');
                }}
              >
                        Studio
                      </Link>
                    </li>
                    <li className={isActive('/contact/')}>
                      
                      <Link 
                        to="/contact/" 
                        onClick={(e) => {
                          e.preventDefault();
                          handleLinkClickHandler('/contact/');
                        }}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>

                <button onClick={toggleOffCanvas} className={offCanvas ? 'header__hamburger active' : 'header__hamburger'}>
                  <span className="hamburger">
                    <span className='lines'></span>
                  </span>
                </button>
              </div>
            </div>

            <div className="logo content-fix">
            <Link 
                to="/" 
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClickHandler('/');
                }}
              >
            <Logo color="#000000" />
          </Link>
        </div>
        </header>

        <div className="logo-mark">
        <Link 
                to="/" 
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClickHandler('/');
                }}
              >
            <LogoMark color="#000000" />
          </Link>
        </div>

        <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
            <div className='off-canvas__inner'>
                <nav className='off-canvas__nav'>
                  <ul>
                    <li className={isActive('/')}>
                    <Link 
                        to="/" 
                        onClick={(e) => {
                          e.preventDefault();
                          handleLinkClickHandler('/');
                        }}
                      >
                        Projects
                      </Link>
                    </li>
                    <li className={isActive('/journal/')}>
                    <Link 
                        to="/journal/" 
                        onClick={(e) => {
                          e.preventDefault();
                          handleLinkClickHandler('/journal/');
                        }}
                      >
                        Journal
                      </Link>
                    </li>
                    <li className={isActive('/studio/')}>
                    <Link 
                        to="/studio/" 
                        onClick={(e) => {
                          e.preventDefault();
                          handleLinkClickHandler('/studio/');
                        }}
                      >
                        Studio
                      </Link>
                    </li>
                    <li className={isActive('/contact/')}>
                    <Link 
                        to="/contact/" 
                        onClick={(e) => {
                          e.preventDefault();
                          handleLinkClickHandler('/contact/');
                        }}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>
            </div>
            <div className='off-canvas__footer'>
              <div className='off-canvas__footer--inner'>
                  <h3 className='title'>Architecture Studio</h3>

                  <div className="logo-mark-mobile">
                    <LogoMark color="#000000" />
                  </div>
              </div>
            </div>
          </div>
    </>
  );
};

export default Header;
