import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { ReactLenis } from 'lenis/react';

import layoutQuery from '../hooks/use-layout-query';
import '../assets/scss/main.scss';

import Header from '../components/header';
import Footer from '../components/footer';

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery();
  const [key, setKey] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [navigateTo, setNavigateTo] = useState(null);
  const [offCanvasActive, setOffCanvasActive] = useState(false); // New state for off-canvas

  const handleLinkClick = (path) => {
    if (typeof window !== 'undefined') {
      if (path === location.pathname) {
        return;
      }
    }

    setNavigateTo(path);
    setIsFading(true);
  };

  useEffect(() => {
    if (isFading) {
      const timer = setTimeout(() => {
        navigate(navigateTo);
      }, 1000); // Duration of the fade-out effect
      return () => clearTimeout(timer);
    }
  }, [isFading, navigateTo]);

  useEffect(() => {
    setIsFading(false);
  }, [location.pathname]);

  return (
    <>
      <ReactLenis root>
          <Header
            location={location}
            data={data}
            pageContext={pageContext}
            handleLinkClick={handleLinkClick}
            offCanvasActive={offCanvasActive}
          />
        <div className={`wrapper fade-wrapper ${isFading ? 'fadeout' : 'fadein'}`}>
          <main key={key}>
            {React.Children.map(children, (child) =>
              React.cloneElement(child, { handleLinkClick })
            )}
          </main>
          <Footer 
            data={data}
            handleLinkClick={handleLinkClick}
          />
        </div>
      </ReactLenis>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

export default Layout;
