import React, { useState, useEffect } from 'react';
import Link from '../utils/link';
import { Logo } from '../components/icons';
import Fade from 'react-reveal/Fade';
import { useInView } from 'react-intersection-observer';
import { useLocation } from '@reach/router';

import layoutQuery from "../hooks/use-layout-query";
import NewsletterPopup from '../components/newsletter-form';

const Footer = ({ site, handleLinkClick }) => {
  const location = useLocation();
  const data = layoutQuery();

  const [activeLink, setActiveLink] = useState(null);
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(window.innerWidth > 768); // Adjust this value to your breakpoint for mobile/desktop
  }, []);

  const handleLinkClickHandler = (link) => {
    setActiveLink(link);
    handleLinkClick(link);
  };

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const handleSubscribeClick = () => {
    setShowNewsletterPopup(true);
  };

  const handleClosePopup = () => {
    setShowNewsletterPopup(false);
  };

  const isStudioOrContactPage = location.pathname === '/contact/';

  return (
    <>
      {/* <NewsletterPopup show={showNewsletterPopup} hidePopup={handleClosePopup} /> */}

      <footer ref={!isStudioOrContactPage && isDesktop ? ref : null} className="footer">
        {/* {(isStudioOrContactPage || inView || !isDesktop) && ( */}
          <Fade distance='150px' bottom>
            <div className='footer__inner'>
              <div className='footer__col'>
                {/* <div className="logo">
                  <Link to='/' title='Wadhal' className='footer__logo' onClick={() => handleLinkClickHandler('/')}>
                    <Logo color='#AE9891'/>
                  </Link>
                </div> */}
              </div>
                  
              <div className='footer__col'>
                <nav>
                  <ul>
                    <li>
                      <Link to='mailto:fahad@wadhal.co.uk'>Email</Link>
                    </li>
                    <li>
                      <Link to='https://www.instagram.com/studiowadhal/'>Instagram</Link>
                    </li>
                    <li>
                      <button to='/careers' onClick={() => handleLinkClickHandler('/privacy-policy')}>Privacy Policy</button>
                    </li>
                    <li>
                      <p className='copyright'>
                        &copy; Wadhal {new Date().getFullYear()}<br/>
                      </p>
                    </li>
                    <li></li>
                  </ul>
                </nav>
              </div>
            </div>
          </Fade>
        {/* )} */}
      </footer>
    </>
  );
}

export default Footer;
